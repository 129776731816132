import { cloneDeep, forEach, has, set } from 'lodash'
import { PermissionAction, PermissionList } from '../models/Permission'
import { initPermissionList } from '../redux/reducers/permissionSlice'
import { store } from '../app/store'
import { UserRole } from '../enum/UserRole'

export const isSuperAdmin = () => {
  const { permission } = store.getState()
  return permission.role === UserRole.SUPERADMIN
}

export const mapPermissionList = (
  permissions: PermissionAction[] | undefined
) => {
  const permissionList = cloneDeep(initPermissionList)
  // Reset all permissions to false
  resetPermissionList(permissionList)
  // Set permissions of the user to true
  if (permissions) {
    forEach(permissions, ({ action, entity }) => {
      if (has(permissionList, [entity, action])) {
        set(permissionList, [entity, action], true)
      }
    })
  }
  return permissionList
}

export const resetPermissionList = (permissionList: PermissionList) => {
  forEach(permissionList, (permissions) => {
    forEach(permissions, (value, key) => {
      set(permissions, key, false)
    })
  })
}

export const isPermissionEnabled = () => process.env.REACT_APP_ENV === '"DEV"'
