import Cookies from 'js-cookie'
import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import Define from '../constants/define'
import authApi from '../http/authApi'
import { handleRefreshToken } from '../http/clientAPI'
import { Spin } from 'antd'
import usePressTab from '../hook/usePressTab'
import { useDispatch } from 'react-redux'
import {
  chooseClient,
  getClients,
  getCompanies,
  getWarehouses,
} from '../redux/reducers/accountSlice'
import { useLocation } from 'react-router-dom'
import clientWarehouseApi from '../http/clientWarehouseApi'
import {
  resetPermissions,
  setPermissions,
  setRole,
} from '../redux/reducers/permissionSlice'
import { UserRole } from '../enum/UserRole'

const AuthGuard: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')
  const ssoURL = `${baseURL}/?s_url=${window.location.href}`
  const cookieXValue = Cookies.get('SSO_COOKIE-X')
  const [isHide, setIsHide] = useState<boolean>(true)
  usePressTab()

  const waitCookie = async () => {
    return await new Promise((resolve, reject) => {
      let timeOut = 0
      const interval = setInterval(() => {
        timeOut += 1
        if (cookieXValue) {
          resolve('')
          clearInterval(interval)
        } else {
          // wait browser get cookie until 3 seconds
          if (timeOut === 30) {
            reject('Cookie not existed')
            clearInterval(interval)
          }
        }
      }, 100)
    })
  }

  const waitLocalStorageGetItem = () => {
    return new Promise((resolve) => {
      localStorage.getItem(Define.USERNAME)
      localStorage.getItem(Define.CODE)
      localStorage.getItem(Define.ROLE)
      localStorage.getItem(Define.USER_ID)
      resolve('')
    })
  }

  useEffect(() => {
    // check if access token is coming to expire to refresh
    const interval = setInterval(() => {
      const expiredAtString = Cookies.get('ACCESS_TOKEN_EXP_AT')
      if (expiredAtString) {
        const timestampExpireToken = Date.parse(expiredAtString)
        // If the token is going to expire in 10 minutes, refresh it
        if (timestampExpireToken - Date.now() < 600 * 1000) {
          handleRefreshToken()
        }
      }
      // run check every 3 minutes
    }, 180 * 1000)
    return () => clearInterval(interval)
  }, [])

  async function getMe() {
    try {
      // wait until cookie is ready
      await waitCookie()

      const res = await authApi.getMe()
      const user = res.data.entry

      localStorage.setItem(
        Define.USERNAME,
        `${user.code}-${user.pre_nom} ${user.nom}`
      )

      localStorage.setItem(Define.CODE, user.code)
      localStorage.setItem(Define.ROLE, user.role)
      localStorage.setItem(Define.MAIL, user.mail)
      if (localStorage.getItem(Define.USER_ID) !== user.id) {
        localStorage.setItem(Define.USER_ID, user.id)
        waitLocalStorageRemove()
      }
      waitLocalStorageGetItem().then(() => {
        setIsHide(false)
      })
      dispatch(setRole({ role: user.role }))
      if (user.role !== UserRole.SUPERADMIN) {
        checkLocalPermissions()
      }
    } catch (e) {
      // if cookie is expired, redirect to login page
      window.location.replace(ssoURL)
    }
  }

  const checkLocalPermissions = () => {
    const localPermissions = localStorage.getItem(Define.PERMISSIONS)
    if (
      localStorage.getItem(Define.CHOOSING_CLIENT) &&
      localStorage.getItem(Define.CHOOSING_COMPANY) &&
      localStorage.getItem(Define.CHOOSING_WAREHOUSE) &&
      localPermissions
    ) {
      dispatch(
        setPermissions({
          permissionList: JSON.parse(localPermissions),
        })
      )
    } else {
      dispatch(resetPermissions())
    }
  }

  useEffect(() => {
    getMe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()

  // save client, warehouse, company to global state
  function setDefaultValueForUserFilters(
    filters: {
      user_role: string
      user_id: string
    },
    localCompany?: string,
    localWarehouse?: string,
    localCompanyNom?: string,
    localWarehouseNom?: string,
    localClient?: string,
    localClientCodenom?: string
  ) {
    dispatch(
      getClients({
        ...filters,
        company_id: localCompany,
        warehouse_id: localWarehouse,
        company_code_nom: localCompanyNom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getCompanies({
        ...filters,
        client_id: localClient,
        warehouse_id: localWarehouse,
        client_code_nom: localClientCodenom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getWarehouses({
        ...filters,
        client_id: localClient,
        company_id: localCompany,
        client_code_nom: localClientCodenom,
        company_code_nom: localCompanyNom,
      })
    )
    dispatch(
      chooseClient({
        client: localClient,
        company: localCompany,
        warehouse: localWarehouse,
      })
    )
  }
  const { pathname } = useLocation()

  useEffect(() => {
    async function getData() {
      // fetch data of 3 filter lists when first time mount
      const searchParams = new URLSearchParams(window.location.search)
      const clientInUrl = searchParams.get('client')
      let warehouseInUrl = searchParams.get('warehouse')
      if (warehouseInUrl && !warehouseInUrl?.includes('ware_')) {
        warehouseInUrl = `ware_${warehouseInUrl}`
      }
      let companyInUrl = searchParams.get('company')
      let localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
      let localClientCodenom =
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
      let localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
      let localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
      let localCompanyNom =
        localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
      let localWarehouseNom =
        localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }

      if (companyInUrl) {
        // In case there is param company in url
        if (
          // If params in url is different from data saved in local storage, fetch data and set it as default
          companyInUrl !== localCompany ||
          warehouseInUrl !== localWarehouse ||
          clientInUrl !== localClient
        ) {
          const companies = await clientWarehouseApi.getFilterCompany(filters)
          const choosingCompany = companies.data.entry.find(
            (item) => item.id === companyInUrl
          )
          setDefaultValueForUserFilters(
            filters,
            companyInUrl,
            undefined,
            choosingCompany?.code_nom,
            undefined,
            undefined,
            undefined
          )
        } else {
          // If params in url are same as localstorage , then treat it as default
          setDefaultValueForUserFilters(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      } else {
        if (localClient && localCompany && localWarehouse) {
          // if there are previous search condition in cache, search by them
          setDefaultValueForUserFilters(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      }
    }
    if (!isHide) {
      getData()
    }
  }, [pathname, isHide])

  const waitLocalStorageRemove = () => {
    return new Promise((resolve) => {
      localStorage.removeItem(Define.CHOOSING_COMPANY)
      localStorage.removeItem(Define.CHOOSING_COMPANY_CODENOM)
      localStorage.removeItem(Define.CHOOSING_CLIENT)
      localStorage.removeItem(Define.CHOOSING_CLIENT_CODENOM)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE_CODENOM)
      resolve('')
      localStorage.removeItem(Define.PERMISSIONS)
    })
  }

  if (!isHide) {
    if (!cookieXValue) {
      if (process.env.NODE_ENV.toString() === 'development') return null
      else window.location.replace(ssoURL)
    } else {
      return <>{children as ReactElement}</>
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Spin size="large" />
    </div>
  )
}

export default AuthGuard
